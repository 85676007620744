import request from '@/utils/request'

export const getList = (page,size,params)=>{
    return request({
        url:'/api/fusion/user/list',
        method:'get',
        params:{...params,page,size}
    });
}

export const getDetail = (id)=>{
    return request({
        url:'/api/fusion/user/detail',
        method:'get',
        params:{id}
    })
}
